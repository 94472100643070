@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");
@import url("https://use.typekit.net/jhl3thi.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary-50: 240 250 252;
    --color-primary-100: 222 244 249;
    --color-primary-200: 188 233 242;
    --color-primary-300: 154 222 236;
    --color-primary-400: 119 210 229;
    --color-primary-500: 85 199 223;
    --color-primary-600: 40 182 212;
    --color-primary-700: 31 142 165;
    --color-primary-800: 22 101 118;
    --color-primary-900: 13 60 70;
    --color-primary-950: 9 40 47;

    --color-white: 255 255 255;
    --color-black: 0 0 0;
    --color-gray-50: 249 250 251;
    --color-gray-900: 17 24 39;

    --color-debug: 204 183 231;

    /* Light Mode */
    --color-primary: var(--color-primary-500);
    --color-background: var(--color-white);
    --color-accent-light: var(--color-gray-50);
    --color-accent-dark: var(--color-gray-900);
  }

  :root[class="dark"] {
    --color-primary-100: var(--color-primary-900);
    --color-background: var(--color-primary-950);
    --color-accent-light: var(--color-primary-700);
    --color-accent-dark: var(--color-primary-900);
  }

  body {
    @apply bg-accent-light dark:bg-accent-dark;
    transition: all 100ms linear;
  }

  main {
    @apply py-3 md:py-12;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif;
  }
}

@layer utilities {
  .prose-link {
    @apply prose-a:no-underline hover:prose-a:underline hover:prose-a:underline-offset-2;
  }

  .prose-reset-list {
    @apply prose-ul:p-0 prose-ul:m-0 prose-li:p-0 prose-li:m-0 prose-li:list-none;
  }

  .prose-reset-text {
    @apply prose-p:p-0 prose-p:m-0;
  }

  .prose-reset-headings {
    @apply prose-headings:p-0 prose-headings:m-0;
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar::-moz-scrollbar {
    display: none;
  }
}
